<template>
  <div class="mb-4">
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item waves-effect waves-light">
        <router-link
          class="nav-link"
          :class="{ 
            active: seccion == 'cpes'
          }"
          :to="{ name: 'listadoCpes' }"
        >
          CPEs
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'listadoMarcasCpes' }"
          class="nav-link"
          :class="{
            active: seccion == 'marcas'
          }"
        >
          Marcas
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'listadoModelosCpes' }"
          class="nav-link"
          :class="{ 
            active: seccion == 'modelos'
          }"
        >
          Modelos
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light" v-if="false">
        <router-link
          :to="{ name: 'listadoIpv4' }"
          class="nav-link"
          v-if="false"
          :class="{
            active: seccion == 'IPv4'
          }"
        >
          Administración IPv4
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CmpSecciones',
  props: ['seccion']
}
</script>

<style scoped>
.no-cursor{
  cursor: default;
}
</style>