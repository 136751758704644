import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/routers`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(router) {
    return apiClient.post(`${router.id}`, router)
  },

  cambiarIP(idRouter) {
    return apiClient.post(`${idRouter}/direccion-ip-vpn/cambiar`)
  },

  eliminar(idRouter) {
    return apiClient.delete(`${idRouter}`)
  },

  enLinea(idRouter) {
    return apiClient.get(`${idRouter}/en-linea`)
  },

  guardar(router) {
    return apiClient.post('', router)
  },

  interfacesJSON(idRouter) {
    return apiClient.get(`${idRouter}/interfaces.json`)
  },

  ip(idRouter) {
    return apiClient.get(`${idRouter}/ip`)
  },

  ispsJSON(idRouter) {
    return apiClient.get(`${idRouter}/isps.json`)
  },

  limpiarYDesenlazarRouter(idRouter) {
    return apiClient.post(`${idRouter}/limpiar-y-desenlazar`)
  },

  puertasEnlaceJSON(idRouter) {
    return apiClient.get(`${idRouter}/puertas-enlace`)
  },

  reiniciar(idRouter) {
    return apiClient.post(`${idRouter}/reiniciar`)
  },


  reiniciarVpn(idRouter) {
    return apiClient.post(`${idRouter}/vpn/reiniciar`)
  },

  reintentarConexionApi(idRouter) {
    return apiClient.post(`${idRouter}/reintentar-conexion-api`)
  },


  renovarCertificadoRouter(idRouter) {
    return apiClient.post(`${idRouter}/renovar-certificado`)
  },

  /**
   * Manda a resincroniza el objetivo en el router Mikrotik a través de la API
   * @param {int} idRouter 
   * @param {string} objetivo 
   * @returns 
   */
  resincronizar(idRouter, objetivo) {
    return apiClient.post(`${idRouter}/resincronizar/${objetivo}`)
  },


  /**
   * Resincroniza la configuración de todos los CPEs de todos los routers
   * @returns 
   */
  resincronizarTodosCpes() {
    return apiClient.post('resincronizar/configuracion-cpes')
  },

  routerJSON(idRouter) {
    return apiClient.get(`${idRouter}.json`)
  },

  routersJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/routers.json`, { params: params })
  },

  //datos nuevos para administrar IPv4
  routersIPv4JSON(params) {
    params = params || {}
    return apiClient.get(`${API}/routersIPv4.json`, { params: params })
  },

  ipv4JSON(params) {
    params = params || {}
    return apiClient.get(`${API}/ipv4.json`, { params: params })
  },

  rangoIpv4JSON(params) {
    params = params || {}
    return apiClient.get(`${API}/rango-ipv4.json`, { params: params })
  },

  ipCpesJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/ipcpes.json`, { params: params })
  },

  serviciosPppoeJSON(idRouter) {
    return apiClient.get(`${idRouter}/servicios-pppoe.json`)
  },

  sincJSON(idRouter) {
    return apiClient.get(`${idRouter}/sinc.json`)
  },

  vrouters1JSON() {
    return apiClient.get(`${API}/vrouters1.json`)
  },

  routerSitioJSON(idSitio) {
    return apiClient.get(`${idSitio}/router-sitio.json`)
  },

  traficoInterfazJSON(idRouter, idInterfaz) {
    return apiClient.get(`${idRouter}/interfaces/${idInterfaz}/trafico.json`)
  },

  traficoInterfacesJSON(idRouter) {
    return apiClient.get(`${idRouter}/interfaces/trafico.json`)
  }
}
