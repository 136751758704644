<template>
  <div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th style="width: 50px"></th>
            <th style="width: 250px">Router</th>
            <th class="text-center" style="width: 200px">
                Gateway (Red LAN)
            </th>
            <th class="text-center" style="width: 200px">
                Mascara de Red o CIDR
            </th>
            <th class="text-center">Administración</th>
            <th style="width: 500px">Uso de red</th>
            <th class="text-center" style=" width: 150px">
                Acción
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(router, index) in routers" :key="`router-${index}`">
            <td></td>
            <td>
              <br />
              <router-link
                :to="{
                  name: 'edicionRouter',
                  params: { id: router.id }
                }"
              >
                {{ router.nombre }}
              </router-link>
            </td>
            <td class="text-center">
              <br />
              {{ router.direccion_ip_router }}
            </td>
            <td class="text-center">
              <br />
              {{ router.cidr }}
            </td>
            <td class="text-center">
              <br />
              <label
                class="label label-primary"
                v-show="router.sipppoe == 0"
              >
                IP Estática
              </label>
              <label
                class="label label-warning"
                v-show="router.sipppoe == 1"
              >
                PPPoE
              </label>
            </td>
            <td>
              <div>
                <span>
                  {{ router.cantidad_cpes_red }} de
                  {{ router.hostmax }}
                </span>
                <div class="stat-percent">
                  {{ router.porciento }}%
                </div>
                <div
                  class="progress progress-striped active"
                  style="height:10px"
                >
                  <div
                    :style="router.porcentaje"
                    class="progress-bar"
                  ></div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <router-link
                :to="{
                  name: 'listadoIpWan',
                  params: {
                    id_router: router.id,
                    red_lan: router.direccion_ip
                  }
                }"
                v-show="router.sipppoe == 0"
                class="btnEnviar btn btn-default"
              >
                <i class="fa fa-bars"></i> Listado
              </router-link>
              <button
                class="btnEnviar btn btn-default"
                v-show="router.sipppoe != 0"
                @click="mesajePPPoE()"
              >
                <i class="fa fa-bars"></i> Listado
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'General',
  props: [ 'routers' ],
  methods: {
    mesajePPPoE() {
      iu.msg.warning(
        'No esta disponble el listado IP de los CPES por que es administración PPPoE'
      )
    }
  }
}
</script>