<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10">
        <h2>Network</h2>
        <ol class="breadcrumb">
          <li>
            <strong>Administracion IPv4</strong>
          </li>
        </ol>
      </div>
    </div>
    <cmp-secciones seccion="IPv4"></cmp-secciones>
    <div id="listadoIPv4" class="wrapper wrapper-content">
      <div class="row">
        <div class="col-md-8 col-md-offset-2">
          <filtrador
            v-on:dato-actualizado="
              dato = $event,
              refrescarRouters()
            "
            :datoini="dato"
            placeholder="Buscar Router"
          ></filtrador>
          &nbsp;
        </div>
      </div>

      <div class="wrapper wrapper-content">
        <div class="tabs-container">
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#tab-1" data-toggle="tab">
                General
              </a>
            </li>
            <li class="">
              <a href="#tab-2" data-toggle="tab">
                Vista de IP disponibles por segmento LAN
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane active" id="tab-1">
              <div class="panel-body">
                <General :routers.sync="routers"/>
              </div>
            </div>

            <div class="tab-pane" id="tab-2">
              <div class="panel-body">
                <IpDisponible/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filtrador from '@/components/Filtrador.vue'
import CmpSecciones from '../CmpSecciones.vue'
import RouterSrv from '@/services/RouterSrv.js'

import General from './General.vue'
import IpDisponible from './IpDisponible.vue'

export default {
  name: 'ListadoIPv4',
  components: { CmpSecciones, Filtrador, General, IpDisponible },
  data: function() {
    return {
      dato: '',
      routers: [],
    }
  },
  created: function() {
    var self = this
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    self.refrescarRouters()
  },
  methods: {
    refrescarRouters: function() {
      var self = this
      this.$router.replace({
        query: {
          dato: self.dato
        }
      })
      let params = { dato: self.dato, con_sitio: true }
      RouterSrv.routersIPv4JSON(params).then(response => {
        self.routers = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
  mounted() {
    $('#listadoIPv4').fadeIn()
  }
}
</script>

<style>
#listadoIPv4 {
  display: none;
}
.btnEnviar:hover {
  color: #fff;
  background-color: #1ab394;
}
</style>
