<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <h5>Router de administración</h5>
        <select
          v-model="ips.id_router"
          @change="cargarIpsLans()"
          class="form-control"
        >
          <option :value="null">Seleccionar</option>
          <option
            v-for="(router, index) in routersA"
            :key="`lista-router-${index}`"
            :value="router.id"
          >
            {{ router.nombre }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <h5>Redes LAN</h5>
        <select
          v-model="ips.ip_red_lan"
          @change="cargarRangoIp()"
          :disabled="ipv4.length == 0"
          class="form-control"
        >
          <option v-for="(ip, index) in ipv4" :key="`ip-${index}`">
            {{ ip.direccion_ip }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <h5>Rango de IPs</h5>
        <select
          v-model="ips.rango_ips"
          @change="cargarIpsCpes()"
          :disabled="rangosIpv4.length == 0"
          class="form-control"
        >
          <option
            v-for="(rango, index) in rangosIpv4"
            :key="`rangoip-${index}`"
            :value="rango.rango_ip"
          >
            {{ rango.rango_imprimir }}
          </option>
        </select>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-4">
        <h5>IPs disponibles</h5>
        <select
          v-model="ips.ip_disponible"
          class="form-control"
          :disabled="ipcpes.length == 0"
        >
          <option
            v-for="(ipwan, index) in ipcpes"
            :key="`ipwan-${index}`"
          >
            {{ ipwan }}
          </option>
        </select>
      </div>
      <div class="col-md-4" v-show="ips.ip_red_lan != null">
        <br />
        <div class="bg-info p-xs b-r-md text-center">
          <div
            v-for="(router, index) in routers"
            :value="router.id"
            :key="`routerp-${index}`"
          >
            <span v-show="ips.ip_red_lan == router.direccion_ip">
              {{ router.host_disponibles }} Host disponibles
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="ips.ip_disponible != null">
        <br />
        <router-link
          :to="{
            name: 'nuevoCpe',
            query: {
              id_router: ips.id_router,
              ip_disponible: ips.ip_disponible
            }
          }"
          class="btn btn-warning btn-outline btn-block"
        >
          <i class="fa fa-plus"></i>
          Crear CPE con IP disponible
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import RouterSrv from '@/services/RouterSrv.js'

export default {
  name: 'IpDisponible',
  data: function() {
    return {
      ips: {
        id_router: null,
        ip_red_lan: null,
        ip_disponible: null,
        ip_wan: '',
        rango_ips: '',
        ip_pppoe: false
      },
      routers: [],
      routersA: [],
      rangosIpv4: [],
      ipv4: [],
      ipcpes: []
    }
  },
  created: function() {
    var self = this
    self.refrescarRouters()
  },
  methods: {
    refrescarRouters: function() {
      var self = this
      this.$router.replace({
        query: {
          dato: self.dato
        }
      })
      let params = { dato: self.dato, con_sitio: true }
      RouterSrv.routersIPv4JSON(params).then(response => {
        self.routers = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
      RouterSrv.routersJSON(params).then(response => {
        self.routersA = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarIpsLans: function() {
      var self = this
      let idRou = { id_router: self.ips.id_router }

      RouterSrv.ipv4JSON(idRou).then(response => {
        self.ipv4 = response.data
        iu.msg.success('Se cargaron las Redes LAN correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las redes LAN'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
      self.ips.ip_red_lan = null
      self.ips.ip_disponible = null
      self.ips.rango_ips = ''
      self.ipcpes = []
      self.rangosIpv4 = []
    },

    cargarIpsCpes: function() {
      var self = this
      let idRou = {
        id_router: self.ips.id_router,
        ip_wan: self.ips.ip_red_lan,
        ip_rango: self.ips.rango_ips
      }

      RouterSrv.ipCpesJSON(idRou).then(response => {
        self.ipcpes = response.data
        if (self.ipcpes == '')
          iu.msg.warning(
            'No se pudieron cargar las IP de los CPES por que es administración PPPoE'
          )
        if (self.ipcpes != '')
          iu.msg.success('Se cargaron las IPs disponibles')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las direcciones ips'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
      self.ips.ip_disponible = null
      self.ipcpes = []
    },

    cargarRangoIp: function() {
      var self = this
      let rangoIp = { red: self.ips.ip_red_lan }

      RouterSrv.rangoIpv4JSON(rangoIp).then(response => {
        self.rangosIpv4 = response.data
        iu.msg.success('Se cargaron los rangos de ips correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el rango de ipes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
      // self.ips.ip_red_lan = null
      // self.ips.ip_disponible = null
      // self.ipcpes = []
    }
  }
}
</script>